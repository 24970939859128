.typeImage {
  width: 75px;
  height: 75px;
  object-fit: cover;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.fullscreenImage {
  max-width: 90vw;
  max-height: 90vh;
  border-radius: 10px;
}
