.container {
  max-width: 80%;
  padding: 10px;
}

.row {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.p {
  margin: 2px;
}
