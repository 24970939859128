.productItemShopId {
  max-width: 100vh; // Или 300px, если нужно
  height: auto; // Высота подстраивается под содержимое
  border: 2px solid #e88401;
  margin: 15px;
  padding: 15px;
  display: flex;
  //   flex-direction: row; // Элементы располагаются по горизонтали
  //   justify-content: center;
  //   align-items: center; // Выравнивание по вертикали
  color: white;
  box-sizing: border-box; // Учитываем padding в ширине

  &_row {
    display: flex;
    flex-direction: row;
  }

  &_padding {
    padding: 10px;
    padding-top: 70px;
  }
  &__content {
    font-size: 16px;
    margin: 0;
    flex: 1; // Контент занимает оставшееся место
    display: flex;
    flex-direction: column; // Внутри блока располагаем элементы вертикально
    gap: 0.5rem;
  }
  &__imageGallery {
    display: flex;
    overflow-x: auto; // Прокрутка для галереи
    gap: 5px;
    padding: 5px 0;
    max-width: 500px; // Ограничение ширины галереи
    white-space: nowrap; // Убираем переносы
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    flex-shrink: 0; // Изображения не сжимаются
  }
}
